//colors
$white_30: rgba(255,255,255,.3);
$white_70: rgba(255,255,255,.7);
$white: #fff;
$color_log_cabin_5_approx: rgba(34,34,34,.05);
$color_outrageous_orange_approx: #f05f40;
$color_pomegranate_approx: #eb3812;
$color_log_cabin_approx: #222;
$color_tuatara_70_approx: rgba(52, 52, 52, .7);
$color_tuatara_approx: rgb(52, 52, 52);
$color_japonica_approx: #dd835f;
$color_outrageous_orange_90_approx: rgba(240,95,64,.9);
$concrete: #f2f2f2;
$color_forest_green_approx: #148f08;
$red: red;

//fonts
$font_0: Merriweather;
$font_1: Helvetica Neue;
$font_2: Arial;
$font_3: sans-serif;
$font_4: Open Sans;

//urls
$url_0: url(../../images/sunset-gang-min.jpg);

//@extend-elements
//original selectors
//.navbar-default .nav >li >a, .navbar-default .nav >li >a:focus
%extend_1 {
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	color: $color_log_cabin_approx;
}


html {
	width: 100%;
	height: 100%;
}
body {
	width: 100%;
	height: 100%;
	font-family: $font_0, $font_1, $font_2, $font_3;
	webkit-tap-highlight-color: $color_log_cabin_approx;
}
hr {
	max-width: 50px;
	border-color: $color_outrageous_orange_approx;
	border-width: 3px;
	&.light {
		border-color: $white;
	}
}
a {
	color: $color_outrageous_orange_approx;
	transition: all .35s;
	&:hover {
		color: $color_pomegranate_approx;
	}
	&:focus {
		color: $color_pomegranate_approx;
	}
}
h1 {
	font-family: $font_4, $font_1, $font_2, $font_3;
}
h2 {
	font-family: $font_4, $font_1, $font_2, $font_3;
}
h3 {
	font-family: $font_4, $font_1, $font_2, $font_3;
}
h4 {
	font-family: $font_4, $font_1, $font_2, $font_3;
}
h5 {
	font-family: $font_4, $font_1, $font_2, $font_3;
}
h6 {
	font-family: $font_4, $font_1, $font_2, $font_3;
}
p {
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 1.5;
}
ul {
	list-style-type: circle;	
    li {
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
    }
}
.bg-primary {
	background-color: $color_outrageous_orange_approx;
}
.bg-dark {
	color: $white;
	background-color: $color_log_cabin_approx;
}
.text-faded {
	color: $white_70;
	&ul {
		li {
			color: $white_70;
		}
	}
}
.text-clipped {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.oneem {
	font-size: 1em;
}
.onepointfiveem {
	font-size: 1.5em;
}
.onepointsevenfiveem {
	font-size: 1.75em;
}
.twoem {
	font-size: 2em;
}
section {
    padding: 100px 0;
    overflow: hidden;
    &.less-pad {
        padding: 50px 0;
    }
    &.top-less-pad {        
        padding: 50px 0 0 0;
    }
}
aside {
	padding: 50px 0;
}
.no-padding {
	padding: 0;
}
.image {
    height: auto;
    width: auto;
    max-width: 100%;
    &.size75 {
        height: 75px;
    }
    &.size90 {
		height: 90px;
	}
    &.rounded {
        border-radius: 5px;
    }    
}
.navbar-default {
	border-color: $color_log_cabin_5_approx;
	font-family: $font_4, $font_1, $font_2, $font_3;
	background-color: $white;
	transition: all .35s;
	.navbar-header .navbar-brand {
		text-transform: uppercase;
		font-family: $font_4, $font_1, $font_2, $font_3;
		font-weight: 700;
		color: $color_outrageous_orange_approx;
		&:hover {
			color: $color_pomegranate_approx;
		}
		&:focus {
			color: $color_pomegranate_approx;
		}
	}
	.nav > li {
		> a {
			@extend %extend_1;
			&:focus {
				@extend %extend_1;
				&:hover {
					color: $color_outrageous_orange_approx;
				}
			}
			&:hover {
				color: $color_outrageous_orange_approx;
			}
		}
		&.active > a {
			color: $color_outrageous_orange_approx!important;
			background-color: transparent;
			&:focus {
				color: $color_outrageous_orange_approx!important;
				background-color: transparent;
				&:hover {
					background-color: transparent;
				}
			}
			&:hover {
				background-color: transparent;
			}
		}
	}
}

header {
	position: relative;
	width: 100%;
	min-height: auto;
	text-align: center;
	color: $white;
	background: $color_japonica_approx;
	background-image: $url_0;
	background-position: center;
	background-size: cover;
	.header-content {
		position: relative;
		width: 100%;
		padding: 100px 15px;
		text-align: center;
		.header-content-inner {
			h1 {
				margin-top: 0;
				margin-bottom: 0;
				text-transform: uppercase;
				font-weight: 700;
			}
			hr {
				margin: 30px auto;
			}
			p {
				margin-bottom: 50px;
				font-weight: 300;
				color: $white_70;
				font-family: $font_4, $font_1, $font_2, $font_3;
			}
		}
	}
	// .nav-hero-btn {
	// 	position: relative;
	// 	transform: translate(0, -100%);
	// }
	@media(max-width:350px) {
		.header-content {
			padding: 130px 15px;
		}
	}
}
@media(min-width:768px) {
	.navbar-default {
		border-color: $white_30;
		background-color: transparent;
		&.affix {
			border-color: $color_log_cabin_5_approx;
			background-color: $white;
			.navbar-header .navbar-brand {
				font-size: 14px;
				color: $color_outrageous_orange_approx;
				&:hover {
					color: $color_pomegranate_approx;
				}
				&:focus {
					color: $color_pomegranate_approx;
				}
			}
			.nav > li > a {
				color: $color_log_cabin_approx;
				&:focus {
					color: $color_log_cabin_approx;
					&:hover {
						color: $color_outrageous_orange_approx;
					}
				}
				&:hover {
					color: $color_outrageous_orange_approx;
				}
			}
		}
		.navbar-header .navbar-brand {
			color: $white_70;
			&:hover {
				color: $white;
			}
			&:focus {
				color: $white;
			}
		}
		.nav > li > a {
			color: $white_70;
			&:focus {
				color: $white_70;
				&:hover {
					color: $white;
				}
			}
			&:hover {
				color: $white;
			}
		}
	}
	header {
		min-height: 100%;
		.header-content {
			position: absolute;
			top: 30%;
			padding: 0 50px;
			transform: translateY(-50%);
			.header-content-inner {
				margin-right: auto;
				margin-left: auto;
				max-width: 1000px;
				p {
					margin-right: auto;
					margin-left: auto;
					max-width: 80%;
				}
			}
		}	
		// .nav-hero-btn {
		// 	position: absolute;
		// 	bottom: 30%;
		// 	transform: translate(-50%,-50%);
		// }
	}
	.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
		.project-category {
			font-size: 16px;
		}
		.project-name {
			font-size: 22px;
		}
	}
}
.section-heading {
	margin-top: 0;
}
#services {
	.service-box {
		margin: 50px auto 0;
		max-width: 400px;
		p {
			margin-bottom: 0;
		}
	}
	ul {
		display: inline-block;
		color: #777;
	}
}
#pricing {	
	ul {
		display: inline-block;
		color: #777;
		li {			
			color: white;
		}
	}
}
#contact {
	.contact-box {		
		margin: 50px auto 0;
		overflow: hidden;
		max-width: 400px;
		text-overflow: ellipsis;
	}
}

@media(min-width:992px) {
	.service-box {
		margin: 20px auto 0;
	}
}
.portfolio-box {
	display: block;
	position: relative;
	margin: 0 auto;
	max-width: 650px;
	.portfolio-box-caption {
		display: block;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		color: $white;
		opacity: 0;
		background: $color_outrageous_orange_90_approx;
		transition: all .35s;
		.portfolio-box-caption-content {
			position: absolute;
			top: 50%;
			width: 100%;
			text-align: center;
			transform: translateY(-50%);
			.project-category {
				padding: 0 15px;
				font-family: $font_4, $font_1, $font_2, $font_3;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 600;
			}
			.project-name {
				padding: 0 15px;
				font-family: $font_4, $font_1, $font_2, $font_3;
				font-size: 18px;
			}
		}
	}
	&:hover .portfolio-box-caption {
		opacity: 1;
	}
}
.text-primary {
	color: $color_outrageous_orange_approx;
}
.btn-default {
	border-color: $white;
	color: $color_log_cabin_approx;
	background-color: $white;
	transition: all .35s;
	&:hover {
		border-color: $color_outrageous_orange_approx;
		color: $color_outrageous_orange_approx;
		background-color: $concrete;
	}
	&:focus {
		border-color: $color_outrageous_orange_approx;
		color: $color_outrageous_orange_approx;
		background-color: $concrete;
	}
	&.focus {
		border-color: $color_outrageous_orange_approx;
		color: $color_outrageous_orange_approx;
		background-color: $concrete;
	}
	&:active {
		border-color: $color_outrageous_orange_approx;
		color: $color_outrageous_orange_approx;
		background-color: $concrete;
		background-image: none;
	}
	&.active {
		border-color: $color_outrageous_orange_approx;
		color: $color_outrageous_orange_approx;
		background-color: $concrete;
		background-image: none;
	}
	&.disabled {
		border-color: $white;
		background-color: $white;
		&:hover {
			border-color: $white;
			background-color: $white;
		}
		&:focus {
			border-color: $white;
			background-color: $white;
		}
		&.focus {
			border-color: $white;
			background-color: $white;
		}
		&:active {
			border-color: $white;
			background-color: $white;
		}
		&.active {
			border-color: $white;
			background-color: $white;
		}
	}
	.badge {
		color: $white;
		background-color: $color_log_cabin_approx;
	}
}
.btn-default[disabled] {
	border-color: $white;
	background-color: $white;
	&:hover {
		border-color: $white;
		background-color: $white;
	}
	&:focus {
		border-color: $white;
		background-color: $white;
	}
	&.focus {
		border-color: $white;
		background-color: $white;
	}
	&:active {
		border-color: $white;
		background-color: $white;
	}
	&.active {
		border-color: $white;
		background-color: $white;
	}
}
fieldset[disabled] {
	.btn-default {
		border-color: $white;
		background-color: $white;
		&:hover {
			border-color: $white;
			background-color: $white;
		}
		&:focus {
			border-color: $white;
			background-color: $white;
		}
		&.focus {
			border-color: $white;
			background-color: $white;
		}
		&:active {
			border-color: $white;
			background-color: $white;
		}
		&.active {
			border-color: $white;
			background-color: $white;
		}
	}
	.btn-primary {
		border-color: $color_outrageous_orange_approx;
		background-color: $color_outrageous_orange_approx;
		&:hover {
			border-color: $color_outrageous_orange_approx;
			background-color: $color_outrageous_orange_approx;
		}
		&:focus {
			border-color: $color_outrageous_orange_approx;
			background-color: $color_outrageous_orange_approx;
		}
		&.focus {
			border-color: $color_outrageous_orange_approx;
			background-color: $color_outrageous_orange_approx;
		}
		&:active {
			border-color: $color_outrageous_orange_approx;
			background-color: $color_outrageous_orange_approx;
		}
		&.active {
			border-color: $color_outrageous_orange_approx;
			background-color: $color_outrageous_orange_approx;
		}
	}
}
.btn-primary {
	// border-color: $color_outrageous_orange_approx;
	// color: $white;
	// background-color: $color_outrageous_orange_approx;
	border-color: $white;
	color: $color_outrageous_orange_approx;
	background-color: $white;
	transition: all .35s;
	margin: 5px;
	&:hover {
		border-color: $color_pomegranate_approx;
		color: $white;
		background-color: $color_pomegranate_approx;
	}
	&:focus {
		border-color: $color_pomegranate_approx;
		color: $white;
		background-color: $color_pomegranate_approx;
	}
	&.focus {
		border-color: $color_pomegranate_approx;
		color: $white;
		background-color: $color_pomegranate_approx;
	}
	&:active {
		border-color: $color_pomegranate_approx;
		color: $white;
		background-color: $color_pomegranate_approx;
		background-image: none;
	}
	&.active {
		border-color: $color_pomegranate_approx;
		color: $white;
		background-color: $color_pomegranate_approx;
		background-image: none;
	}
	&.disabled {
		border-color: $color_outrageous_orange_approx;
		background-color: $color_outrageous_orange_approx;
		&:hover {
			border-color: $color_outrageous_orange_approx;
			background-color: $color_outrageous_orange_approx;
		}
		&:focus {
			border-color: $color_outrageous_orange_approx;
			background-color: $color_outrageous_orange_approx;
		}
		&.focus {
			border-color: $color_outrageous_orange_approx;
			background-color: $color_outrageous_orange_approx;
		}
		&:active {
			border-color: $color_outrageous_orange_approx;
			background-color: $color_outrageous_orange_approx;
		}
		&.active {
			border-color: $color_outrageous_orange_approx;
			background-color: $color_outrageous_orange_approx;
		}
	}
	.badge {
		color: $color_outrageous_orange_approx;
		background-color: $white;
	}
}
.btn {
	border: 0;
	border-radius: 300px;
	text-transform: uppercase;
	font-family: $font_4, $font_1, $font_2, $font_3;
	font-weight: 700;
	&.btn-reverse {
		border: 2px solid $color_outrageous_orange_approx;
		color: $white;
		background-color: $color_outrageous_orange_approx;
		transition: all .35s;
		margin: 5px;
	}
}
.btn-reverse {
	&:hover {
		border-color: $color_pomegranate_approx;
		color: $color_pomegranate_approx;
		background-color: $white;
	}
	&:focus {
		border-color: $color_pomegranate_approx;
		color: $color_pomegranate_approx;
		background-color: $white;
	}
	&.focus {
		border-color: $color_pomegranate_approx;
		color: $color_pomegranate_approx;
		background-color: $white;
	}
	&:active {
		border-color: $color_pomegranate_approx;
		color: $color_pomegranate_approx;
		background-color: $white;
	}
	&.active {
		border-color: $color_pomegranate_approx;
		color: $color_pomegranate_approx;
		background-color: $white;
	}
}
.btn-primary[disabled] {
	border-color: $color_outrageous_orange_approx;
	background-color: $color_outrageous_orange_approx;
	&:hover {
		border-color: $color_outrageous_orange_approx;
		background-color: $color_outrageous_orange_approx;
	}
	&:focus {
		border-color: $color_outrageous_orange_approx;
		background-color: $color_outrageous_orange_approx;
	}
	&.focus {
		border-color: $color_outrageous_orange_approx;
		background-color: $color_outrageous_orange_approx;
	}
	&:active {
		border-color: $color_outrageous_orange_approx;
		background-color: $color_outrageous_orange_approx;
	}
	&.active {
		border-color: $color_outrageous_orange_approx;
		background-color: $color_outrageous_orange_approx;
	}
}
.btn-xl {
	padding: 15px 30px;
}
.btn-180 {
	min-width: 180px;
}
::-moz-selection {
	text-shadow: none;
	color: $white;
	background: $color_log_cabin_approx;
}
::selection {
	text-shadow: none;
	color: $white;
	background: $color_log_cabin_approx;
}
img::selection {
	color: $white;
	background: 0 0;
}
img::-moz-selection {
	color: $white;
	background: 0 0;
}
#map {
	height: 300px;
	overflow: visible;
	position: relative;
}
.form-group {
    .form-control {
        display: block;
        width: 100%;
        padding: 24px 18px;
        margin-bottom: 25px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #666;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        .form-control::placeholder { 
            color:#666; 
        }
    }
    textarea {
        height: 200px;
    }
}
.alert {
    margin: 0 auto;
    text-align: center;
    color: #f05f40;
    font-size: 1.15em;
	&.alert--success {
		color: $color_forest_green_approx;
		font-size: 1.35em;
	}
	&.alert--error {
		color: $red;
	}
	&.alert--loading .fa {
		padding: 10px;
		animation: spin 2s infinite linear;
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
#about a {
	color: $color_tuatara_70_approx;
	&:hover {
		color: $color_tuatara_approx;
	}
	&:focus {
		color: $color_tuatara_approx;
	}
}
.call-to-action h2 {
	margin: 0 auto 20px;
}
.no-gutter > [class*=col-] {
	padding-right: 0;
	padding-left: 0;
}
.open > .dropdown-toggle {
	&.btn-default {
		border-color: $color_outrageous_orange_approx;
		color: $color_outrageous_orange_approx;
		background-color: $concrete;
		background-image: none;
	}
	&.btn-primary {
		border-color: $color_pomegranate_approx;
		color: $white;
		background-color: $color_pomegranate_approx;
		background-image: none;
	}
	&.btn-reverse {
		border-color: $color_pomegranate_approx;
		color: $color_pomegranate_approx;
		background-color: $white;
	}
}

#footer {
	position: relative;
	.built-by {
		position: absolute;
		bottom: 0;
		right: 15px;
	}
}